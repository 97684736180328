import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import MatchWidget from 'ground-web/components/v1/MatchWidget';
import ContentRenderer from '../../components/content-renderer';
import Article from '../../components/article';
import SEO from '../../components/seo';
import { decodeHtml } from '../../helpers';
import { parseMatch, renderButtons } from '../../helpers/matches';
import bg from '../../images/bg-beira-rio.png';

const Banner = styled.figure`
  position: relative;
  background: url(${bg});
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  * {
    width: 80%;
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0.5em;
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    flex-direction: row;
    justify-content: space-around;

    * {
      width: unset;
      flex-grow: 0;
      flex-shrink: 1;
      margin: unset;
    }
  }
`;

export default function Page(props) {
  const { pageContext } = props;
  const { match, page } = pageContext;
  const { title, content } = page;

  const parsedMatch = parseMatch(match, page);

  const matchData = {
    finished: false,
    score: null,
    championship: {
      name: match.championship,
    },
    date: parsedMatch.dateUndefined
      ? 'A definir'
      : parsedMatch.matchDate.locale('pt').format('dddd, DD/MM'),
    time: parsedMatch.timeUndefined
      ? 'A definir'
      : parsedMatch.matchDate.format('HH:mm'),
    local: match.stadium,
    homeTeam: {
      shield: {
        source: match.homeImg,
        alt: 'Logo do time da casa',
      },
      name: match.homeTeamName,
    },
    visitorTeam: {
      shield: {
        source: match.visitorImg,
        alt: 'Logo do time visitante',
      },
      name: match.visitorTeamName,
    },
  };

  const labels = {
    kickoff: 'Início',
    countdownStart: 'Tempo para a partida:',
    date: 'Data',
    championship: 'Campeonato',
    share: 'Compartilhar',
  };

  return (
    <>
      <Banner>
        <Container>
          <MatchWidget match={matchData} labels={labels} theme={{}}>
            <ActionsContainer>
              {renderButtons(parsedMatch, false)}
            </ActionsContainer>
          </MatchWidget>
        </Container>
      </Banner>
      <SEO title={decodeHtml(title)} />
      <Article>
        <ContentRenderer content={content} />
      </Article>
    </>
  );
}
